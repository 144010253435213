import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import { generalEvents, inventoryEvents } from "../../utils/amplitudeEvents";
import { sendEvent } from "../../utils/amplitude";
import { CHOOSE_EXPEIRENCE_STATUS, SANDBOX_STATUS, checkIfIsSandboxStatus } from "../../consts/onbording";

export const TOGGLE_THEME = "TOGGLE_THEME";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const GET_PREFERENCES = "GET_PREFERENCES";
export const TOGGLE_SETTINGS = "TOGGLE_SETTINGS";
export const CHANGE_TABLE_PAGE_SIZE = "CHANGE_TABLE_PAGE_SIZE";
export const TOGGLE_INVENTORY_MODE = "TOGGLE_INVENTORY_MODE";
export const UPDATE_INVENTORY_COLUMN_VISIBILITY_MODEL= "UPDATE_INVENTORY_COLUMN_VISIBILITY_MODEL";
export const UPDATE_USER_PREFERENCE = "UPDATE_USER_PREFERENCE";


const updateUserPreferences = (preferences) => {
  return action(async (dispatch) => {
    let userThemePref = preferences["https://infralight.co/theme_dark"];
    let userDrawerPref = preferences["https://infralight.co/sidebar_closed"];
    let tbalePageSizePref =
      preferences["https://infralight.co/table_page_size"];
    let inventory_mode_pref =
      preferences["https://infralight.co/inventory_mode"];
    let inventory_columnVisibilityModel =
      preferences["https://infralight.co/inventory_columnVisibilityModel"];
    let workflowsFiltersOpen = preferences["https://infralight.co/workflowsFiltersOpen"];
    const isSandboxStatus = checkIfIsSandboxStatus(preferences);
    dispatch({
      type: GET_PREFERENCES,
      payload: {
        themeDark: userThemePref,
        sidebarClosed: userDrawerPref,
        tablePageSize: tbalePageSizePref,
        inventory_mode: inventory_mode_pref,
        inventory_columnVisibilityModel: inventory_columnVisibilityModel,
        workflowsFiltersOpen,
        isSandboxStatus,
      },
    });
  });
};

const toggleAppTheme = (themeDark) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    let payload = { preferenceName: "theme_dark", preferenceValue: !themeDark };

    const req = requestWrapper.sendRequest(
      `${BACKEND_URL}/users`,
      "PUT",
      payload
    );

    dispatch({
      type: TOGGLE_THEME,
      payload: {},
    });
    // only is user if changing from light mode send event
    sendEvent(generalEvents.drakMode, {
      action: `User selected ${!themeDark ? "dark" : "light"} theme`,
    });
  });
};

const toggleSidebar = (isSidebarClosed) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    let payload = {
      preferenceName: "sidebar_closed",
      preferenceValue: !isSidebarClosed,
    };

    const req = requestWrapper.sendRequest(
      `${BACKEND_URL}/users`,
      "PUT",
      payload
    );

    dispatch({
      type: TOGGLE_SIDEBAR,
      payload: {},
    });
    sendEvent(generalEvents.sidebar, {
      action: `User toggled sidebar to be ${
        !isSidebarClosed ? "close" : "open"
      }`,
    });
  });
};

const organizationChange = (accountId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    let payload = { preferenceName: "account_id", preferenceValue: accountId };

    const req = requestWrapper.sendRequest(
      `${BACKEND_URL}/users`,
      "PUT",
      payload
    );

    sendEvent(generalEvents.organizationChange, {
      action: "User changed organiztion",
      organization: accountId,
    });

    return req;
  });
};

const toggleSettings = (bool) => {
  return action(async (dispatch) => {
    dispatch({
      type: TOGGLE_SETTINGS,
      payload: { bool },
    });
  });
};

const updateTabelPageSize = (size) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    let payload = { preferenceName: "table_page_size", preferenceValue: size };

    const req = requestWrapper.sendRequest(
      `${BACKEND_URL}/users`,
      "PUT",
      payload
    );

    dispatch({
      type: CHANGE_TABLE_PAGE_SIZE,
      payload: size,
    });
    sendEvent(generalEvents.tablePageSizeSelection, {
      action: "User changed page size",
      size,
    });
  });
};

const toggleInventoryMode = (mode, isUpdateUserPref) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    let payload = { preferenceName: "inventory_mode", preferenceValue: mode };

    if (isUpdateUserPref) {
      const req = requestWrapper.sendRequest(
        `${BACKEND_URL}/users`,
        "PUT",
        payload
      );
    }

    dispatch({
      type: TOGGLE_INVENTORY_MODE,
      payload: mode,
    });
    sendEvent(inventoryEvents.inventoryScreenMode, {
      action: "User changed the screen view mode",
      mode,
    });
  });
};
const updateInventoryColumnVisibilityModel = (columnVisibilityModel) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    let payload = { preferenceName: "inventory_columnVisibilityModel", preferenceValue: columnVisibilityModel };

    const req = requestWrapper.sendRequest(
      `${BACKEND_URL}/users`,
      "PUT",
      payload
    );

    dispatch({
      type: UPDATE_INVENTORY_COLUMN_VISIBILITY_MODEL,
      payload: columnVisibilityModel,
    });
    sendEvent(inventoryEvents.inventoryScreenMode, {
      action: "User changed the inventory column visibility model",
      columnVisibilityModel
    });
  });
};
//generic function to update user preference
const updateUserPreference = (preferenceName, preferenceValue) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const payload = { preferenceName, preferenceValue };

    const req = requestWrapper.sendRequest(
      `${BACKEND_URL}/users`,
      "PUT",
      payload
    );
    if ([SANDBOX_STATUS.key,CHOOSE_EXPEIRENCE_STATUS.key].includes(preferenceName)) return;
    dispatch({
      type: UPDATE_USER_PREFERENCE,
      payload: { field: preferenceName, value: preferenceValue },
    });
  });
}

export {
  toggleAppTheme,
  toggleSidebar,
  updateUserPreferences,
  organizationChange,
  toggleSettings,
  updateTabelPageSize,
  toggleInventoryMode,
  updateInventoryColumnVisibilityModel,
  updateUserPreference,
};
