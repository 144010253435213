import { CHURNZERO_API_KEY } from "../consts/config";
import { getSubdomain } from "./helpers";

const ENTERPRISE = "ENTERPRISE";

export const initChurnzero = (
    userEmail,
    accountName,
    tierType,
) => {
    const ChurnZero = window.ChurnZero;
    const subDomain = getSubdomain();
    const isLocal = subDomain?.includes("localhost");
    const isFireflySession = userEmail?.includes("gofirefly") || userEmail?.includes("firefly") || userEmail?.includes("infralight");
    const isV2 = subDomain === "v2";
    const isEnterpriseAccount = tierType === ENTERPRISE;
    if (isLocal || isFireflySession || isV2 || !isEnterpriseAccount) return;

    ChurnZero?.push(['setAppKey', CHURNZERO_API_KEY]);
    ChurnZero?.push(['setContact', accountName, userEmail]);
};

export const sendChurnZeroEvent = (eventName = '', description ='', quantity = 1, customFields = {}) => {
    const ChurnZero = window.ChurnZero;
    ChurnZero?.push(['trackEvent', eventName, description, quantity, customFields]);
};

export const setChurnZeroModule = (module = '') => {
    const ChurnZero = window.ChurnZero;
    ChurnZero?.push(['setModule', module]);
}
