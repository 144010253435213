const primary = "#9872FE";

export default {
    "code[class*=\"language-\"]": {
      "color": "#c5c8c6",
      "textShadow": "0 1px rgba(0, 0, 0, 0.3)",
      "fontFamily": "Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace",
      "direction": "ltr",
      "textAlign": "left",
      "#c5c8c6Space": "pre",
      "wordSpacing": "normal",
      "wordBreak": "normal",
      "lineHeight": "1.5",
      "MozTabSize": "4",
      "OTabSize": "4",
      "tabSize": "4",
      "WebkitHyphens": "none",
      "MozHyphens": "none",
      "msHyphens": "none",
      "hyphens": "none"
    },
    "pre[class*=\"language-\"]": {
      "color": "#c5c8c6",
      "textShadow": "0 1px rgba(0, 0, 0, 0.3)",
      "fontFamily": "Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace",
      "direction": "ltr",
      "textAlign": "left",
      "#c5c8c6Space": "pre",
      "wordSpacing": "normal",
      "wordBreak": "normal",
      "lineHeight": "1.5",
      "MozTabSize": "4",
      "OTabSize": "4",
      "tabSize": "4",
      "WebkitHyphens": "none",
      "MozHyphens": "none",
      "msHyphens": "none",
      "hyphens": "none",
      "padding": "1em",
      "margin": ".5em 0",
      "overflow": "auto",
      "borderRadius": "0.3em",
      "background": "#1d1f21"
    },
    ":not(pre) > code[class*=\"language-\"]": {
      "background": "#1d1f21",
      "padding": ".1em",
      "borderRadius": ".3em"
    },
    "comment": {
      "color": "#c5c8c6"
    },
    "prolog": {
      "color": "#c5c8c6"
    },
    "doctype": {
      "color": "#c5c8c6"
    },
    "cdata": {
      "color": "#c5c8c6"
    },
    "punctuation": {
      "color": "#c5c8c6"
    },
    ".namespace": {
      "Opacity": ".7"
    },
    "property": {
      "color": primary
    },
    "keyword": {
      "color": primary
    },
    "tag": {
      "color": primary
    },
    "class-name": {
      "color": "#FFFFB6",
      "textDecoration": "underline"
    },
    "boolean": {
      "color": "#99CC99"
    },
    "constant": {
      "color": "#99CC99"
    },
    "symbol": {
      "color": "#f92672"
    },
    "deleted": {
      "color": "#f92672"
    },
    "number": {
      "color": "#FF73FD"
    },
    "selector": {
      "color": "#33fdd8"
    },
    "attr-name": {
      "color": "#33fdd8"
    },
    "string": {
      "color": "#33fdd8"
    },
    "char": {
      "color": "#33fdd8"
    },
    "builtin": {
      "color": "#33fdd8"
    },
    "inserted": {
      "color": "#33fdd8"
    },
    "variable": {
      "color": "#C6C5FE"
    },
    "operator": {
      "color": "#EDEDED"
    },
    "entity": {
      "color": "#FFFFB6",
      "cursor": "help"
    },
    "url": {
      "color": primary
    },
    ".language-css .token.string": {
      "color": "#c5c8c6"
    },
    ".style .token.string": {
      "color": "#c5c8c6"
    },
    "atrule": {
      "color": "#F9EE98"
    },
    "attr-value": {
      "color": "red"
    },
    "function": {
      "color": "#DAD085"
    },
    "regex": {
      "color": "#E9C062"
    },
    "important": {
      "color": "#fd971f",
      "fontWeight": "bold"
    },
    "bold": {
      "fontWeight": "bold"
    },
    "italic": {
      "fontStyle": "italic"
    }
  };