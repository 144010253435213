import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// components
import { isDemoApplication } from "../../utils/helpers";
import NavLinks from "../../components/appSidebar/navLinks/navLinks";
import AppLogo from "../../components/appSidebar/appLogo/appLogo";
import AccountTierBanner from "../../components/appSidebar/accountTierBanner/accountTierBanner";
import CompanyLogo from "../../components/appSidebar/companyLogo/companyLogo";
import ProvisioningBtn from "../../components/appSidebar/provisioningBtn/provisioningBtn";

import { setReb2bScript } from "../../utils/thirdParties";
import "./appSidebar.scss";

const AppSidebar = () => {
  const sidebarClosed = useSelector(
    (state) => state.userPreferencesReducer.sidebarClosed
  );
  const isDemo = isDemoApplication();
  useEffect(() => {
    if (isDemo) {
      setReb2bScript();
    }
  }, [])
  
  return (
    <div className={`AppSidebar ${isDemo ? 'demo' : ''}`}>
      <AppLogo sidebar />
      <div className="col between">
        <div className="AppSidebar__links col">
          <ProvisioningBtn />
          <NavLinks />
        </div>
        <div
          className={`AppSidebar__actions ${sidebarClosed ? "no-padding" : ""}`}
        >
          <AccountTierBanner />
          <CompanyLogo />
        </div>
      </div>
    </div>
  );
};

export default AppSidebar;
