import React, { useState, useEffect } from 'react';
import moment from 'moment';
import compact from 'lodash/compact';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import NewAppModal from '../../../shared/newAppModal/newAppModal';
import CiProgress from './ciProgress';
import CiPolicyCostSummary from './ciPolicyCostSummary';
import PostApply from './postApply/postApply';
import PostPlan from './postPlan/postPlan';
import { timeDifferenceInWords } from '../../../utils/formatting';
import PostPlanConfig from './postPlanGraph/postPlanConfig/postPlanConfig';
import CiCode from './ciCode/ciCode';
import { ReactComponent as GuardrailsIcon } from "../../../Images/general_icons/sidebar/guardrails.svg";

import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { appToast } from '../../../shared/appToast/appToast';
import GradientBorderButton from '../../../shared/gradientBorderButton/gradientBorderButton';
import GeneralSummaryConfig from './postPlanGraph/generalSummaryConfig/generalSummaryConfig';
import { CI_STEPS, CI_STEPS_MODES } from '../../../consts/ci-workflows';
import { getGeneralPolicyCostSummaryData } from '../../../utils/workflowsHelper';
import { sendEvent } from '../../../utils/amplitude';
import { CiEvents } from '../../../utils/amplitudeEvents';

import GuardrailBlock from './guardrailBlock/guardrailBlock';
import { onClickAddGuardrail } from '../../guardrails/guardrailsModal/guardrailsHelper';

import AppSwitch from '../../../shared/appSwitch/appSwitch';
import InitStep from './initStep/initStep';
import { useDispatch } from 'react-redux';
import { clearRunLogData } from '../../../redux/actions/ciWorkflowsActions';

import './ciModal.scss';

const STATUSES_ICONS = {
    planning: "ellipsis-h",
    applying: "ellipsis-h",
    "plan_success": "check",
    "plan_error": "times",
    "apply_error": "times",
    "apply_success": "check-double",
}
const INIT_ERROR = "init_error";
const CiModal = ({ runData = {}, modalVisible, setModalVisible, openDefaultPolicyCostDrawer }) => {
    const {  plan = [], apply = [], createdAt: startedAt = "", workspaceName = "", workspaceId = "", commitId = "",
    commitUrl = "", pullRequestId = '', pullRequestUrl = '', status = '', planTaskId: codeId = '', runId = "", 
    stepParam = "", tabParam = "", buildUrl = "", runPage = 1, hasGuardrails = false, repo = "" } = runData;
    const planSummary = plan[0] || {};
    const applySummary = apply[0] || {};

    const { id: planId = '', costResultsSummary = {}, scanResultsSummary = {}, parsedDataSummary: planParsedDataSummary = {}, 
    changedFilesCodeSummary = {}, guardrailsSummary = {}, availableLogs: availableLogsFromPlan = [] } = planSummary;
    const { id: applyId = '', parsedDataSummary: applyParsedDataSummary = {}, availableLogs: availableLogsFromApply = [] } = applySummary;
    const guardrailsCount = guardrailsSummary.violationsCount || 0;
    const filesCount = changedFilesCodeSummary?.filesCount || 0;

    const codeIsEnabled = codeId && filesCount > 0;
    const guardrailIsEnabled = planId && guardrailsCount > 0;
    const initIsEnabled = availableLogsFromPlan?.includes(CI_STEPS.init) ||  status === INIT_ERROR;
    const initDefault = availableLogsFromPlan?.includes(CI_STEPS.init) && status === INIT_ERROR;
    const planIsEnabled = planId && status !== INIT_ERROR;

    const isStepParamValid = [CI_STEPS.code, CI_STEPS.plan, CI_STEPS.apply, CI_STEPS.guardrail, CI_STEPS.init].includes(stepParam);
    const isTabParamValid = Object.keys(CI_STEPS_MODES).includes(tabParam);
    const defaultStep = isStepParamValid ? stepParam : (status.includes(CI_STEPS.apply) ? CI_STEPS.apply : guardrailIsEnabled ? CI_STEPS.guardrail :
    initDefault ? CI_STEPS.init : CI_STEPS.plan);
    const defaultGeneralSummaryData = openDefaultPolicyCostDrawer ? getGeneralPolicyCostSummaryData(planSummary) : {};

    const [currentStep, setCurrentStep] = useState(defaultStep);
    const [openPlanConfig, setOpenPlanConfig] = useState(false);
    const [openGeneralConfig, setOpenGeneralConfig] = useState(openDefaultPolicyCostDrawer || false);
    const [openInTab, setOpenInTab] = useState("");
    const [selectedResource, setSelectedResource] = useState({});
    const [generalSummaryConfigData, setGeneralSummaryConfigData] = useState(defaultGeneralSummaryData);
    const [didMount, setDidMount] = useState(false);
    
    const dispatch = useDispatch();

    const { t } = useTranslation("ci-pipeline");
    const history = useHistory();

    const isCodeCurrentStep = currentStep === CI_STEPS.code;
    const isPlanCurrentStep = currentStep === CI_STEPS.plan;
    const isApplyCurrentStep = currentStep === CI_STEPS.apply;
    const isGuardrailCurrentStep = currentStep === CI_STEPS.guardrail;

    const [modeByStep, setModeByStep] = useState(isTabParamValid ? tabParam : isPlanCurrentStep ? CI_STEPS_MODES.graph : isApplyCurrentStep ? CI_STEPS_MODES.table : "");
    
    useEffect(() => {
        return () => dispatch(clearRunLogData());  
    }, []);

    useEffect(() => {
        setOpenPlanConfig(false);
        setSelectedResource({});
        setOpenInTab("");
        if (didMount) {
            setOpenGeneralConfig(false);
            const stepsAvailiability = compact([codeIsEnabled ? CI_STEPS.code : "", guardrailIsEnabled ? CI_STEPS.guardrail : "", planId ? CI_STEPS.plan : "", applyId ? CI_STEPS.apply : ""]);
            sendEvent(CiEvents.ciClickedWorkflowStepTab, { workspaceID: workspaceId, workspaceRunID: runId, stepTabName: currentStep, stepsAvailiability });
            setModeByStep(isPlanCurrentStep ? CI_STEPS_MODES.graph : isApplyCurrentStep ? CI_STEPS_MODES.table : "");
        } else {
            setDidMount(true);
        }
    }, [currentStep]);

    const handleOpenConfig = (data, triggerOpenTab = "") => {
        setOpenInTab(triggerOpenTab);
        setSelectedResource(data);
        setOpenPlanConfig(true);
    }
    const handleSummaryClick = (summaryData) => {
        setGeneralSummaryConfigData(summaryData);
        setOpenGeneralConfig(true);
    }

    const renderStepTitle = () => {
        if (isGuardrailCurrentStep) return (
        <div className='row g5'>
            <GuardrailsIcon className="CiModal__content-header-title-guardrailsIcon" />
            <span>{t("guardrailsStep.title")}</span>
            <span className="sub-title CiModal__subTitle">{timeDifferenceInWords("", moment(startedAt), moment())}</span>
        </div>);
        if (isCodeCurrentStep) return t("codeStep.title");

        const isStepFailed = isPlanCurrentStep ? !!planParsedDataSummary?.plan?.isFailed : isApplyCurrentStep ? !!applyParsedDataSummary?.apply?.isFailed : status === INIT_ERROR;
        const isNoChanges = status?.includes("no_changes");
        const step = isPlanCurrentStep ? "PLAN" : isApplyCurrentStep ? "APPLY" :  "INIT";
        const ciStatusIcon = isStepFailed ? STATUSES_ICONS.plan_error : (isPlanCurrentStep ? STATUSES_ICONS.plan_success : STATUSES_ICONS.apply_success);
        const iconClassname = isStepFailed ? "plan_error" : (isPlanCurrentStep ? "plan_success" : "apply_success");
        const title = isNoChanges ? `${step} NO CHANGES` : `${step} ${isStepFailed ? "FAILED" : "COMPLETED"}`;
        return <div className="row g5">
            <FontAwesomeIcon className={`CiStepsCollapse__header-status-icon table ${iconClassname}-table`} icon={["fas", ciStatusIcon]}/>
            <span>{title}</span>
            <span className="sub-title CiModal__subTitle">{timeDifferenceInWords("", moment(startedAt), moment())}</span>
        </div>
    }
    
    const handleShareClick = () => {
        const tab = isApplyCurrentStep || isPlanCurrentStep ? modeByStep : "";
        navigator.clipboard.writeText(`${window.location.origin}/workflows?workflow=${workspaceId}&page=${runPage}&run=${runId}&step=${currentStep}&tab=${tab}`);
        sendEvent(CiEvents.ciSharedWorkflowsLink, { workspaceID: workspaceId, workspaceRunID: runId });
        appToast( "success", t("alerts.shareBtn.title"), t("alerts.shareBtn.message"))
    }
    
    const renderModalTitle = () => {
        const isPR = !!(pullRequestId && pullRequestUrl);
        const shortCommitId = commitId.slice(0, 6);
        const commitOrPrlink = isPR ? pullRequestUrl : commitUrl;
        const idToShow = isPR ? pullRequestId : shortCommitId;
        return <div className='row g5'>
            <span className="bold">{workspaceName}</span>
            <div className="row g8">
                <div className="row g5">
                  {idToShow ? <span className="ModuleCall__select-link" onClick={(e) => {
                        e.stopPropagation();
                        window.open(commitOrPrlink, "_blank");
                    }}>{`(#${idToShow})`}</span> : null}
                 {buildUrl && <FontAwesomeIcon className="ModuleCall__select-link" icon='external-link-alt' size="sm" onClick={(e) => { 
                      e.stopPropagation();
                      window.open(buildUrl, "_blank");
                    }}/>}
                </div>
            </div>
        </div>
    }
    const onModeChange = (newMode) => {
        setModeByStep(newMode);
        const event = `${CiEvents.changedViewMode} (workspace ${isApplyCurrentStep ? "apply" : "plan"})`
        sendEvent(event, { viewMode: newMode })
    };
    const renderModeTabs = () => {
        const shouldRenderTabs = isPlanCurrentStep ? availableLogsFromPlan.includes("raw") || availableLogsFromPlan.includes("json")
        : isApplyCurrentStep ? availableLogsFromApply.includes("apply") : false;
        if (!shouldRenderTabs) return null;
        return (
        <AppSwitch
            items={[
            {
                text: <div className="row g5"><FontAwesomeIcon icon={isApplyCurrentStep ? "list" : "project-diagram" } />{t(`ciModes.${isApplyCurrentStep ? CI_STEPS_MODES.table : CI_STEPS_MODES.graph}`)}</div>,
                value: isApplyCurrentStep ? CI_STEPS_MODES.table : CI_STEPS_MODES.graph,
            },
            {
                text: <div className="row g5"><FontAwesomeIcon icon="file-code" />{t("ciModes.log")}</div>,
                value: CI_STEPS_MODES.log,
            },
        ]}
            active={modeByStep}
            onChange={onModeChange}
        />);
    }

    return (
      <NewAppModal
        visible={modalVisible}
        handleClose={() => setModalVisible(false)}
        destroyOnClose
        centered
        width={"85vw"}
        bodyClassName="CiModal__modal"
        title={renderModalTitle()}
        subtitle=""
        iconSrc={null}
        footer={null}
        headerSuffix={<div className="CiModal__shareBtn">
                        <GradientBorderButton onClick={handleShareClick}>
                                <FontAwesomeIcon className="CiModal__shareBtn-icon" icon={faShareAlt}/>
                        </GradientBorderButton>
                      </div>}>
            <div className="CiModal__modal-leftSide col">
                <CiProgress onChangeStep={(step) => setCurrentStep(step)} planId={planId} applyId={applyId} codeIsEnabled={codeIsEnabled} defaultStep={defaultStep} guardrailIsEnabled={guardrailIsEnabled} initIsEnabled={initIsEnabled} planIsEnabled={planIsEnabled}/>
                <div className="CiModal__modal-badges col g10">
                    {isApplyCurrentStep && !hasGuardrails && (
                    <div className="CiModal__modal-guardrailBtn row g8 pointer" onClick={(e) => onClickAddGuardrail(e, history, workspaceName, repo)}>
                        <GuardrailsIcon className="purple-text pointer"/>
                        <span>Add Guardrail</span>
                    </div>)}
                    {!isCodeCurrentStep && <CiPolicyCostSummary data={{ costResultsSummary, scanResultsSummary, parsedDataSummary: planParsedDataSummary?.plan }} onClick={handleSummaryClick}/>}
                </div>
            </div>
            <div className={`CiModal__content ${(openPlanConfig || openGeneralConfig) ? 'hiddenOverflow' : ''}`}>
                <div className="CiModal__content-header row between">
                    <span className="CiModal__content-header-title bold">
                        {renderStepTitle()}
                    </span>
                    {renderModeTabs()}
                </div>
                <div className="CiModal__content-body">
                    {isCodeCurrentStep ? <CiCode codeId={codeId} filesCount={filesCount} />
                    : isPlanCurrentStep ? <PostPlan planId={planId} parsedDataSummary={planParsedDataSummary?.plan} handleOpenConfig={handleOpenConfig} workspaceData={{ runId, workspaceId }} taskId={codeId} 
                    isLogModeSelected={modeByStep === CI_STEPS_MODES.log} tCi={t}/>
                    : isApplyCurrentStep ? <PostApply planId={planId} applyId={applyId} parsedDataSummary={applyParsedDataSummary?.apply} handleOpenConfig={handleOpenConfig} taskId={codeId} isLogModeSelected={modeByStep === CI_STEPS_MODES.log} tCi={t}/>
                    : isGuardrailCurrentStep ? <GuardrailBlock taskId={planId}  />
                    : <InitStep taskId={planId} tCi={t} />
                }
                </div>
                {openPlanConfig &&
                    <PostPlanConfig
                      visible={openPlanConfig}
                      onClose={() => setOpenPlanConfig(false)}
                      data={selectedResource}
                      defaultTab={openInTab}
                      workspaceData={{ runId, workspaceId, plan }}
                    />}
                {openGeneralConfig && 
                    <GeneralSummaryConfig
                        visible={openGeneralConfig}
                        onClose={() => setOpenGeneralConfig(false)}
                        generalData={generalSummaryConfigData}
                        runId={planId || applyId}
                        handleOpenConfig={handleOpenConfig}
                    />}
            </div>
     </NewAppModal>
    )
};

export default CiModal;
