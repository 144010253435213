import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { EXPERIENCE_STEP_OPTIONS, ONBOARDING_CHOOSE_EXPERIENCE_OPTIONS, SANDBOX_STATUS } from "../../../consts/onbording";
import { useDispatch } from "react-redux";
import { updateUserPreference } from "../../../redux/actions/userPreferencesActions";
import "./chooseExperience.scss";

const ChooseExperienceStep = ({ handleOnboardingNext }) => {
    const [hoverDescription, setHoverDescription] = useState("");
    const { t } = useTranslation("onBoarding", { keyPrefix: "choose-experience" });
    const dispatch = useDispatch();

    const handleOnClick = async(e, key) => {
        if (key === EXPERIENCE_STEP_OPTIONS.startFreeTrial) {
            return handleOnboardingNext(e);
        }
        await dispatch(updateUserPreference(SANDBOX_STATUS.key, true));
        handleOnboardingNext(e, true);
        window.location.reload();
    };
    return (
        <div className="basic-100 col g40">
            <div className="ChooseExperience col center">
                <div className="ChooseExperience__list">
                {ONBOARDING_CHOOSE_EXPERIENCE_OPTIONS.map((item = "") => {
                        const IconComponent = item.icon;
                        return <div key={item.key} className="ChooseExperience__list-gradient" onClick={(e) => handleOnClick(e, item.key)} 
                                onMouseEnter={() => setHoverDescription(t(`${item.key}Description`))} onMouseLeave={() => setHoverDescription('')} >
                                <div className={`basic-card ChooseExperience__list-item col center`}>
                                    <div className="ChooseExperience__list-item-icon row center">
                                        <div className="ChooseExperience__list-item-icon-container row center">
                                            <div className="ChooseExperience__list-item-icon-mask row center">
                                                <IconComponent className="ChooseExperience__list-item-icon-img" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ChooseExperience__list-item-title">{t(`${item.key}Title`)}</div>
                                </div>
                            </div>
                    })}
                </div>
            </div>
            <span className="ChooseExperience-description">{hoverDescription}</span>
        </div>
    )
};

export default ChooseExperienceStep;