import React from "react";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as GuardrailsIcon } from "../../../Images/general_icons/sidebar/guardrails.svg";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";

const GuardrailsDisplay = ({ guardrails = [], onGuardrailsClick }) => {
    const hasGuardrails = guardrails.length > 0;
    const { t } = useTranslation("ci-pipeline");
    return (
        <Popover
            key={uuidv4()}
            overlayClassName="FiltersRowBadges__popover"
            content={hasGuardrails ? (
            <div className="FiltersRowBadges__popover-wrapper">
                <div>
                <h5>Guardrails</h5>
                </div>
                <div className="FiltersRowBadges__popover-wrapper__items">
                {guardrails.map((item) => (
                <div
                    className="basic-filter-badge default-cursor row g5"
                    key={uuidv4()}
                >
                    <GuardrailsIcon />
                    <span>{item}</span>
                </div>
                ))}
                </div>
            </div>) 
            : <span className="FiltersRowBadges__popover-wrapper">{t("tooltips.noGuardrailsOnWorkspace")}</span>
            }
            placement="topLeft">
            <GuardrailsIcon className={`CiStepsCollapse__guardrails ${hasGuardrails ? "purple-text" : "disabledText pointer" }`} onClick={onGuardrailsClick}/>
        </Popover>
    );
};

export default GuardrailsDisplay;
