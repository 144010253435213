import React, { useEffect, useMemo, useState } from "react";
import { Form, TreeSelect } from "antd";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { EMPTY_CI_WORKFLOWS_AGGS_FILTERS } from "../../../consts/general";
import { getWorkflowsAggs } from "../../../redux/actions/ciWorkflowsActions";
import { WORKFLOWS_STATUSES_TITLES } from "../../../utils/workflowsHelper";
import { initialiazeSection } from "../../guardrails/guardrailsModal/guardrailsHelper";

const WorkflowsSection = ({ form, initialValues = {}, defaultWizardOpen }) => {
    const [treeSearch, setTreeSearch] = useState({});
    const [aggsLoading, setAggsLoading] = useState(false);

    const ciAggs = useSelector((state) => state.ciWorkflowsReducer.ciAggs) || {};
    const { labels = [], repos = [], workspaceName: workspaceNames = [] } = ciAggs;

    const allWorkspaces = useMemo(() => workspaceNames.map((workspace) => workspace.name || ""), [ciAggs]);
    const allLabels = useMemo(() => labels.map((label) => label?.name || ""), [labels]);
    const allRepos = useMemo(() => repos.map((repo) => repo?.name || ""), [repos]);
    const allStatuses = useMemo(() => Object.keys(WORKFLOWS_STATUSES_TITLES).map((status) => ({ title: WORKFLOWS_STATUSES_TITLES[status], key: status, value: status})), []);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isEmpty(labels) || isEmpty(repos) || isEmpty(workspaceNames)) {    
            fetchCiAggs();
        }
        if (!isEmpty(initialValues)) {
            const { workspaceNames = [], workspaceRepositories = [], workspaceLabels = [], workspaceStatus = [] } = initialValues?.scope || {};
            const fieldsToInit = { workspaceNames, workspaceRepositories, workspaceLabels, workspaceStatus };
            const initValues = defaultWizardOpen ? fieldsToInit : initialiazeSection(fieldsToInit, Object.keys(fieldsToInit));
            form.setFieldsValue({ ...initValues });
        }
    }, [])

    const fetchCiAggs = async() => {
        setAggsLoading(true);
        await dispatch(getWorkflowsAggs("", EMPTY_CI_WORKFLOWS_AGGS_FILTERS, ["repo", "labels", "workspaceName"]));
        setAggsLoading(false);
    }

    const onChangeField = (field, value) => {
        form.setFieldsValue({ [field]: value });
    };

    const renderTagsSelection = (options = [], placeholder , values = [], onChange = () => null, fieldName, optionIsObj = false, withoutSearch = false) => {
        return (
            <TreeSelect
                placeholder={placeholder}
                showSearch
                loading={aggsLoading}
                disabled={aggsLoading}
                filterTreeNode={(search, item) => item?.title?.toLowerCase().indexOf(search?.toLowerCase()) >= 0}
                multiple
                treeDefaultExpandAll
                searchValue={treeSearch[fieldName] || ""}
                treeData={[{
                    title: "All",
                    value: "all",
                    children: options.map((option) => ({ title: optionIsObj ? option.title : option, value: optionIsObj ? option.value : option, key: optionIsObj ? option.key : option }))
                }]}
                onSearch={(val) => setTreeSearch({ ...treeSearch, [fieldName]: val })}
                onKeyDown={(e) =>{
                    const shouldAddSearchInput =  e.key === 'Enter' && treeSearch[fieldName] && fieldName && !values.includes(treeSearch[fieldName]) && !withoutSearch;
                    if (shouldAddSearchInput) {
                        e.preventDefault();
                        const newArray = [...values, treeSearch[fieldName]];
                        setTreeSearch({ ...treeSearch, [fieldName]: "" });
                        form.setFieldsValue({ [fieldName]: newArray });
                        onChange(newArray);
                    }
                }}
                treeCheckable
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                style={{ width: '100%' }}
                onChange={onChange}
                value={values}
                showArrow
            />
        )
    }
    return (
        <div className="col g20">
            <div className="row g40">
                <Form.Item label="Workspaces" name="workspaceNames" style={{ flexDirection: "column", marginBottom: "0", width: '50%' }}>
                    {renderTagsSelection(allWorkspaces, "Enter workspace names", initialValues?.scope?.workspaceNames || [], (val) => onChangeField("workspaceNames", val), "workspaceNames")}
                </Form.Item>
                <Form.Item label="Repositories" name="workspaceRepositories" style={{ flexDirection: "column", marginBottom: "0", width: '50%' }}>
                 {renderTagsSelection(allRepos, "Enter workspace repositories" ,initialValues?.scope?.workspaceRepositories || [], (val) => onChangeField("workspaceRepositories", val), "workspaceRepositories")}
                </Form.Item>
            </div>
            <div className="row g40">
                <Form.Item label="Workspace Labels" name="workspaceLabels" style={{ flexDirection: "column", marginBottom: "0", width: '50%' }}>
                    {renderTagsSelection(allLabels, "Enter workspace labels", initialValues?.scope?.workspaceLabels || [], (val) => onChangeField("workspaceLabels", val), "workspaceLabels")}

                </Form.Item>
                <Form.Item label="Workspace Status" name="workspaceStatus" style={{ flexDirection: "column", marginBottom: "0", width: '50%' }}>
                    {renderTagsSelection(allStatuses, "Enter workspace status", initialValues?.scope?.workspaceStatus || [], (val) => onChangeField("workspaceStatus", val), "workspaceStatus", true, true)}
                </Form.Item>
            </div>
        </div>
    )
};

export default WorkflowsSection;