import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import customStyle from "./customStyle";

import "./logSyntaxHighlighter.scss";

const LogSyntaxHighlighter = ({ log, customClassName = "" }) => {
    return (
        <div className={`LogSyntaxHighlighter ${customClassName}`}>
            <SyntaxHighlighter
                style={customStyle}
                showLineNumbers={false}
                wrapLongLines
                language="log">
                {log}
            </SyntaxHighlighter>
        </div>
    );
};

export default LogSyntaxHighlighter;