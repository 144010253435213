import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import RequestWrapper from "./utils/requestWrapper";
// import { BACKEND_URL } from "./consts/config";

// json translation files
import common from './localization/en/common.json'
import ciPipeline from './localization/en/ci-pipeline.json'
import compose from './localization/en/compose.json'
import discovery from './localization/en/discovery.json'
import header from './localization/en/header.json'
import inventory from './localization/en/inventory.json'
import integrations from './localization/en/integrations.json'
import notifications from './localization/en/notifications.json'
import sidebar from './localization/en/sidebar.json'
import users from './localization/en/users.json'
import excluded_assets from './localization/en/excluded-assets.json'
import excluded_drifts from './localization/en/excluded-drifts.json'
import event_explorer from './localization/en/event-explorer.json'
import insights from './localization/en/insights.json'
import general from './localization/en/general.json'
import azure_integration from './localization/en/azure-integration.json'
import google_chat from './localization/en/google-chat-integration.json'
import dashboard from './localization/en/dashboard.json'
import backupDR from './localization/en/backup-dr.json'
import assetHistory from './localization/en/asset-history.json'
import onBoarding from './localization/en/onboarding.json'

// Documentation
// https://www.i18next.com/overview/configuration-options

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    // keySeparator: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    resources: {
      en: {
          common,
          compose,
          header,
          inventory,
          notifications,
          integrations,
          insights,
          sidebar,
          users,
          "excluded-assets": excluded_assets,
          "excluded-drifts": excluded_drifts,
          "event-explorer": event_explorer,
          "ci-pipeline": ciPipeline,
          "azure-integration": azure_integration,
          general,
          discovery,
          "google-chat": google_chat,
          dashboard,
          backupDR,
          'asset-history': assetHistory,
          onBoarding
      }
    },
    saveMissing: true,
    missingKeyNoValueFallbackToKey: false, // Don't show keys, if translations not loaded yet
    // missingKeyHandler: (lng, ns, key, fallbackValue) => { //send alert on missing key
    //   const requestWrapper = new RequestWrapper();
    //   const url = `${BACKEND_URL}/metric/translation`;
    //   return requestWrapper.sendRequest(
    //     url,
    //     "POST",
    //     { lng, ns, key, fallbackValue, container: "app" },
    //     true,
    //     null,
    //     false
    //   );
    // },
    parseMissingKeyHandler: (key) => {
      return ``; // this will return when missing keys
    },
  });

  export default i18n; 