import React, { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toggleViewModal } from "../../redux/actions/screenViewActions";
import { v4 as uuidv4 } from "uuid";
// components
import PageName from "../../components/appHeader/pageName/pageName";
import BreadCrumbs from "../../components/appHeader/breadCrumbs/breadCrumbs";
import HeaderSearch from "../../components/appHeader/headerSearch/headerSearch";
import Feedback from "../../components/appHeader/feedback/feedback";
import NavigationSearch from "../../components/navigationSearch/navigationSearch";
import UserConnected from "../../components/appHeader/userConnected/userConnected";
import ViewSwitcher from "../../components/appHeader/viewSwitcher/viewSwitcher";
import ViewManagerModal from "../../components/appHeader/viewManagerModal/viewManagerModal";
import OrganizationSelect from '../../components/appHeader/organizationSelect/organizationSelect';
import GradientBorderButton from '../../shared/gradientBorderButton/gradientBorderButton';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InviteUser from "../../components/users/usersList/inviteUser";
import { getAllRoles } from "../../redux/actions/profilesActions";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import ExploreSandboxAlert from "../../components/appHeader/exploreSandboxAlert/exploreSandboxAlert";
import "./appHeader.scss";

const baseRoles = [
  {
    description: "All permissions",
    id: uuidv4(),
    name: "Admin",
  },
];

const AppHeader = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation('header');

  const visibleSearchRoutes = ["/iac"];
  const visibleViewSelectRoutes = ["/inventory"];
  const [showHeaderSearch, setShowHeaderSearch] = useState(false);
  const [showViewSelect, setShowViewSelect] = useState(false);
  const [showUserInviteModal, setShowUserInviteModal] = useState(false);
  const [rolesLoading, setRolesLoading] = useState(false);

  const viewModalOpen = useSelector((state) => state.screenViewReducer.viewModalOpen);
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  const roles = useSelector((state) => state.profilesReducer.roles) || [];
  const isSandboxStatus = useSelector((state) => state.userPreferencesReducer.isSandboxStatus);

  useLayoutEffect(() => {
    const pathName = location?.pathname || '';
    const isVisibleSearch = !!(visibleSearchRoutes.find((route) => pathName.includes(route)));
    setShowHeaderSearch(isVisibleSearch);
    setShowViewSelect(visibleViewSelectRoutes.includes(location?.pathname));
    return;
  }, [location?.pathname]);

  const handleClickInviteBtn = async () => {
    if (!roles?.length) {
       setRolesLoading(true);
       await dispatch(getAllRoles());
       setRolesLoading(false);
    }
    setShowUserInviteModal(true);
  }

  return (
    <div className="AppHeader row between">
      <div className="AppHeader__routerIndication col">
        <PageName />
        <BreadCrumbs />
      </div>
      <ExploreSandboxAlert isSandboxStatus={isSandboxStatus} />
      <NavigationSearch/>
      <div className="AppHeader__actions row">
        {showHeaderSearch && <HeaderSearch />}
        {showViewSelect && (
          <ViewSwitcher
            openViewModal={() => dispatch(toggleViewModal(false))}
          />
        )}
        <OrganizationSelect />
        <Feedback /> 
        {!isViewer && 
        <GradientBorderButton onClick={handleClickInviteBtn} tooltip={t("tooltips.invite-btn")}
          children={<div className="AppHeader__invite row g5">
          {rolesLoading ? <Spin indicator={<LoadingOutlined/>}/> : <FontAwesomeIcon icon="user-plus" />}
        </div>} />}
        <UserConnected />
      </div>
       <InviteUser
          visible={showUserInviteModal && !isViewer}
          handleClose={() => setShowUserInviteModal(false)}
          handleCloseRefresh={() => setShowUserInviteModal(false)}
          rolesArr={[...baseRoles, ...roles]}
      />
      <ViewManagerModal
        visible={viewModalOpen?.open}
        handleClose={() => dispatch(toggleViewModal(false))}
        isEditMode={viewModalOpen?.edit}
      />
    </div>
  );
};

export default AppHeader;
