import React, { useState } from "react"
import { Steps } from "antd";
import { ReactComponent as CodeIcon } from "../../../Images/ci/steps/code.svg"
import { ReactComponent as PlanIcon } from "../../../Images/ci/steps/plan.svg"
import { ReactComponent as InitIcon } from "../../../Images/ci/steps/init.svg"
import { ReactComponent as ApplyIcon } from "../../../Images/ci/steps/apply.svg"
import { ReactComponent as GuardrailsIcon } from "../../../Images/general_icons/sidebar/guardrails.svg";

import { CI_STEPS } from "../../../consts/ci-workflows";
import "./ciProgress.scss";

const getNumberClassByLength = (length) => length === 5 ? "five" : length === 4 ? "four" : length === 3 ? "three" : "";
const CiProgress = ({ onChangeStep = () => null, planId = "", applyId = "", codeIsEnabled = false, defaultStep = CI_STEPS.plan, guardrailIsEnabled = false, initIsEnabled = false, planIsEnabled = false }) => {
    const ciSteps = [
        ...(codeIsEnabled ? [{ key: CI_STEPS.code, title: "Code", icon: CodeIcon }] : []),
        ...(initIsEnabled ? [{ key: CI_STEPS.init, title: "Init", icon: InitIcon }] : []),
        ...(planIsEnabled ? [{ key: CI_STEPS.plan, title: "Plan", icon: PlanIcon }] : []),
        ...(guardrailIsEnabled  ? [{ key: CI_STEPS.guardrail, title: "Guardrails", icon: GuardrailsIcon }] : []),
        ...(applyId ? [{ key: CI_STEPS.apply, title: "Apply", icon: ApplyIcon }] : []),
    ]
    const defaultStepIndex = ciSteps.findIndex(step => step.key === defaultStep) || 0;
    const [current, setCurrent] = useState(defaultStepIndex); 

    const getStatusByIndex = (index) => current > index ? "finish" : ( current === index ? "active" : "next");

    const onClickStep = (index = 1) => {
        const step = ciSteps[index]?.key;
        onChangeStep(step);
        setCurrent(index);
    }
    const stepsClassLength = getNumberClassByLength(ciSteps?.length);
    return (
        <div className={`CiProgress ${stepsClassLength}`}>
            <Steps direction="vertical" size="small" current={current}>
                {ciSteps.map((step, index) => (
                    <Steps.Step 
                        key={step.key} 
                        className={`CiProgress__${step.key}`} 
                        title={step.title} 
                        icon={<step.icon className={`CiProgress-icon ${getStatusByIndex(index)}`}/>} 
                        onClick={() => onClickStep(index)} 
                    />
                ))}
            </Steps>
        </div>
    );
};

export default CiProgress;