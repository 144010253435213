import React from "react";
import { Drawer, Modal } from "antd";
import { useTranslation } from "react-i18next";

const ExploreSandboxModal = ({ onClose, onConfirm }) => {
    const { t } = useTranslation("onBoarding", { keyPrefix: "exploreSandboxModal" });
    return (
        <Modal
            visible={true}
            onCancel={onClose}
            closable
            title=""
            footer={null}
            destroyOnClose={true}
            className="ExploreSandboxModal"
            width="600px"
            centered
        >
            <div className="ExploreSandboxModal__container col g15">
                <span className="ExploreSandboxModal__title bold">
                    {t("title")}
                </span>
                <span className="ExploreSandboxModal__sub">
                    {t("subtitle")}
                </span>
                <div className="ExploreSandboxModal__button center" onClick={onConfirm}>
                    {t("button")}
                </div>
                <span>
                    {t("bottom")}
                </span>
            </div>
        </Modal>
    )
}

export default ExploreSandboxModal;