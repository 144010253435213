import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { Collapse } from 'antd';
import { getWorkflowsAggs, handleSetCiFilters } from '../../redux/actions/ciWorkflowsActions';
import InventorySingelFilter from '../../components/inventory/inventoryFilters/inventorySingelFilter';
import { ReactComponent as Chevron } from '../../Images/general_icons/chevron.svg';
import { ReactComponent as RepoIcon } from '../../Images/ci/filters/repo.svg';
import { ReactComponent as CiToolIcon } from '../../Images/ci/filters/ci-tool.svg';
import { ReactComponent as StatusIcon } from '../../Images/ci/filters/status.svg';
import { ReactComponent as TagsIcon } from "../../Images/general_icons/tags.svg";

import { sendEvent } from '../../utils/amplitude';
import { CiEvents } from '../../utils/amplitudeEvents';
import { WORKFLOWS_STATUSES_TITLES, formatCiToolName } from '../../utils/workflowsHelper';
import { ciDataSourceIcons } from '../../utils/icons';

import './ciFilters.scss';

const { Panel } = Collapse;
const filterIcons = {
    repo: RepoIcon,
    ciTool: CiToolIcon,
    status: StatusIcon,
    label: TagsIcon,
}
const CiFilters = ({ searchInput, workflowsFiltersOpen, handleFiltersCollapse, setWorkflowPage, modalsClosed }) => {
    const [activePanel, setActivePanel] = useState("0");

    const { t } = useTranslation("ci-pipeline", { keyPrefix: "filters" });
    const ciAggs = useSelector((state) => state.ciWorkflowsReducer.ciAggs) || {};
    const ciFilters = useSelector((state) => state.ciWorkflowsReducer.ciFilters) || {};
    const { repos: reposAggs = [], ciTool: ciToolAggs = [], labels: labelsAggs = [], status: statusAggs = [] } = ciAggs;
    const { repos: reposFilters = [], ciTool: ciToolFilters = [], labels: labelsFilters = [], status: statusFilters = [] } = ciFilters;

    const formattedStatuses = useMemo(() => statusAggs.map((elem = {}) => ({ ...elem, formattedName: WORKFLOWS_STATUSES_TITLES[elem.name] })), [ciAggs])
    const dispatch = useDispatch();
    useEffect(() => {
        if (!modalsClosed) return;
        fetchCiAggs();
    }, [searchInput, ciFilters, modalsClosed]);
    
    const fetchCiAggs = async () => dispatch(getWorkflowsAggs(searchInput, ciFilters));
    const handleSetFilters = async (newFilters) => {
        setWorkflowPage(0);
        dispatch(handleSetCiFilters(newFilters));
    }
    const panelHeader = (title, activeArr, idx, iconKey) => {
        const IconComponent = filterIcons[iconKey];
        const isActive = !isEmpty(activeArr) || activePanel == idx;
        return (
          <div className="CiFilters__item row" >
            <IconComponent className={`CiFilters__item-icon ${isActive ? 'active' : ''}`}/>
            <span
              className={`CiFilters__item-title row ${isActive ? 'active' : '' }`}
            >
              {workflowsFiltersOpen && <span>{title}</span>}
              {!isEmpty(activeArr) && workflowsFiltersOpen && (
                <span className="purple-text">{`(${activeArr?.length})`}</span>
              )}
            </span>
          </div>
        );
    };

    const handleCutsomArrow = (e) => <Chevron style={{ transform: e.isActive ? "translateY(2px)" : "rotate(-90deg) translateY(2px)", transition: "all .2s ease", width: "10px"}} />;
    const ciToolOptions = useMemo(() => ciToolAggs.map((elem = {}) => ({ ...elem, icon: ciDataSourceIcons(elem?.name) ,formattedName: formatCiToolName(elem?.name) })), [ciAggs]);

    return (
        <div className="CiFilters col g10">
            <span className={`CiFilters__title ${!workflowsFiltersOpen ? 'closed' : ''} bold`}>Filters</span>
            <div className="CiFilters__container col">
                <Collapse
                    expandIconPosition="right"
                    expandIcon={handleCutsomArrow}
                    activeKey={workflowsFiltersOpen ? activePanel : ""}
                    onChange={(panel) => {
                        setActivePanel(panel);
                        if (!workflowsFiltersOpen) {
                            handleFiltersCollapse();
                        }
                        const thePanel = panel || activePanel;
                        const filterName = thePanel === "1" ? "repository" : "ci tool";
                        const actionType = panel === "1" || panel === "2" ? "opened" : "closed";
                        sendEvent(CiEvents.ciFilterCollapseClick, { filterName, actionType });
                     }
                    }
                    accordion
                >
                    <Panel header={panelHeader(t('repository'), reposFilters, "1", "repo")} key="1">
                    <InventorySingelFilter
                        allTypes={reposAggs}
                        selectedTypes={(selected) => handleSetFilters({ ...ciFilters, repos: selected })}
                        checkedList={reposFilters}
                        title={t('search')}
                        active={activePanel === "1"}
                        noAnimation
                        onRegularFilterSearch={(val) => sendEvent(CiEvents.ciSearchTextFromFilter, {searchedText: val, filterName: "repository"})}
                    />
                    </Panel>
                    <Panel header={panelHeader(t('ciTool'), ciToolFilters, "2", "ciTool")} key="2">
                    <InventorySingelFilter
                        allTypes={ciToolOptions}
                        selectedTypes={(selected) => handleSetFilters({ ...ciFilters, ciTool: selected })}
                        checkedList={ciToolFilters}
                        title={t('search')}
                        active={activePanel === "2"}
                        noAnimation
                        onRegularFilterSearch={(val) => sendEvent(CiEvents.ciSearchTextFromFilter, {searchedText: val, filterName: "ci tool"})}
                    />
                    </Panel>
                    <Panel header={panelHeader(t('label'), labelsFilters, "3", "label")} key="3">
                        <InventorySingelFilter
                            allTypes={labelsAggs}
                            selectedTypes={(selected) => handleSetFilters({ ...ciFilters, labels: selected })}
                            checkedList={labelsFilters}
                            title={t('search')}
                            active={activePanel === "3"}
                            noAnimation
                            onRegularFilterSearch={(val) => sendEvent(CiEvents.ciSearchTextFromFilter, {searchedText: val, filterName: "labels"})}
                        />
                    </Panel>
                    {/* <Panel header={panelHeader(t('owner'),  ciFilters.owner, "3")} key="3">
                    <InventorySingelFilter
                        allTypes={ciAggs.owner}
                        selectedTypes={(selected) => handleSetFilters({ ...ciFilters, owner: selected })}
                        checkedList={ciFilters.owner}
                        title={t('search')}
                        active={activePanel === "3"}
                    />
                    </Panel> */}
                    <Panel header={panelHeader(t('status'), statusFilters, "4", "status")} key="4">
                    <InventorySingelFilter
                        allTypes={formattedStatuses}
                        selectedTypes={(selected) => handleSetFilters({ ...ciFilters, status: selected })}
                        checkedList={statusFilters}
                        title={t('search')}
                        active={activePanel === "4"}
                        noAnimation
                        onRegularFilterSearch={(val) => sendEvent(CiEvents.ciSearchTextFromFilter, {searchedText: val, filterName: "last status"})}
                    />
                    </Panel>
                </Collapse>
            </div>
        </div>
    );
};

export default CiFilters;