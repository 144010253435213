import React from "react";
import { Handle, Position } from "react-flow-renderer";
import AssetTypeImage from "../../../../../shared/assetTypeImage/assetTypeImage";
import { ReactComponent as TagIcon } from "../../../../../Images/ci/guardrails/tag.svg";
import { ReactComponent as NoTagsIcon } from "../../../../../Images/ci/no-tags.svg";
import {
  capitalizeFirst,
  formatAwsStringWithUnderscore,
  providerAssetImagesFormatter,
  renderEllipsis,
} from "../../../../../utils/formatting";
import { Divider, Popover } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WORKFLOWS_ACTIONS_MAP } from "../../../../../consts/ci-workflows";

import "./diagramResourceBlock.scss";

const ResourcePopover = ({ data = {} }) => {
    const { action, name, assetType, provider, elispsisLength, severity = '', costObj, tags = {}, isTaggable = false} = data;
    const { monthlyCost = 0, costComponents = [] } = costObj;
    
    const tagsLength = Object.keys(tags || {}).length;

    let isContainCostComponentByUsage = false;
        costComponents.forEach((component = {}) => {
            if (component.monthlyCost == 0) {
                isContainCostComponentByUsage = true;
            }
        });

    const formattedMonthlyCost = monthlyCost == 0 ? 'Cost by usage' : 
    `$${parseFloat(monthlyCost).toFixed(2)}/m${isContainCostComponentByUsage ? ` + cost by usage` : ''}`;

    
    return <div className="ResourcePopover col">
        <div className="ResourcePopover__header row g10">
            <AssetTypeImage
                assetType={assetType}
                provider={providerAssetImagesFormatter(provider)}
                customStyle={{ height: "25px", width: "25px", borderRadius: "4px" }}/>
            <div className="ResourcePopover__header-textContainer col">
                <span className="bold">
                {formatAwsStringWithUnderscore(assetType)}
                </span>
                <span className="sub-title">
                {renderEllipsis(name, elispsisLength)}
                </span>
            </div>
        </div>
        <Divider />
        <div className="ResourcePopover__body">
           {action && 
            <div className="row g5">
                <span className="ResourcePopover__body-title">Action</span>
                <div className={`ResourcePopover__icon ${action}`}/>
                <span className="ResourcePopover__body-value">{WORKFLOWS_ACTIONS_MAP[action]}</span>
            </div>}
            {severity &&
            <div className="row g5">
                <span className="ResourcePopover__body-title">Severity</span>
                <FontAwesomeIcon icon="exclamation" className={`ResourcePopover__icon ${severity}`}/>
                <span className="ResourcePopover__body-value">{capitalizeFirst(severity)}</span>
            </div>}
            {monthlyCost ?
            <div className="row g5">
                <span className="ResourcePopover__body-title">Cost</span>
                <FontAwesomeIcon icon="dollar-sign" className={`ResourcePopover__icon cost diagram`}/>
                <span className="ResourcePopover__body-value">{formattedMonthlyCost}</span>
            </div> : null}
            {(tagsLength > 0 || isTaggable) &&
                <div className="row g5">
                    <span>Tags coverage</span>
                    {tagsLength > 0 ?
                        <>
                            <TagIcon className="ResourcePopover__icon tags"/>
                            <span className="ResourcePopover__body-value">{tagsLength} Tags</span>
                        </>
                    : isTaggable ?
                    <>
                        <NoTagsIcon className="ResourcePopover__icon noTags"/>
                        <span className="ResourcePopover__body-value">No Tags</span>
                    </> : null}
                </div>
            }
        </div>
    </div>
}

const getCostObj = (costElement = {}, action = "") => {
    const { before = {}, after = {},  } = costElement;
    const costObj = action === 'delete' ? before : after;
    return costObj;
};

const DiagramResourceBlock = ({ resource = {}, onClick }) => {
  const { data = {} } = resource;
  const { name = '', assetType = '', provider = '', sourceHandleVisible, targetHandleVisible,
   action = '',  count = 1, isCostResults, costResults = [], highestSeverity = '', tags = {}, isTaggable = false } = data;
  const elispsisLength = assetType.length + 4;

  const severity = highestSeverity.toLowerCase();
  const costObj = isCostResults ? getCostObj(costResults[0], action)  : {};
  const resourcePopoverData = { action, assetType, name, provider, elispsisLength, severity, costObj, tags, isTaggable };
  const isMultipleResources = count > 1;
  const tagsLength = Object.keys(tags || {}).length;

  return (
    <Popover content={<ResourcePopover data={resourcePopoverData}/>} className="ResourcePopover" placement="bottomRight">
        {isMultipleResources && <div className={`row g10 DiagramResourceBlock ci ${action} second`} onClick={onClick}/>}
        {count > 2 && <div className={`row g10 DiagramResourceBlock ci ${action} third`} onClick={onClick}/>}
        <div className={`row g10 DiagramResourceBlock ci ${action}`} onClick={onClick}>
        {sourceHandleVisible && <Handle type="source" id="a" position={Position.Right} />}
        <AssetTypeImage
            assetType={assetType}
            provider={providerAssetImagesFormatter(provider)}
            customStyle={{ height: "30px", width: "30px", borderRadius: "4px" }}
        />
        <div className='DiagramResourceBlock__textContainer col'>
            <div className="row between">
                <span className="bold">
                    {formatAwsStringWithUnderscore(assetType)}
                </span>
                <div className="DiagramResourceBlock__icons row g5">
                    {tagsLength > 0? <TagIcon className="ResourcePopover__icon tags diagram"/> : isTaggable ? <NoTagsIcon className="ResourcePopover__icon noTags diagram"/> : null}
                    {severity && <FontAwesomeIcon icon="exclamation" className={`ResourcePopover__icon ${severity} diagram`}/>}
                    {isCostResults && <FontAwesomeIcon icon="dollar-sign" className={`ResourcePopover__icon cost diagram`}/>}
                </div>

            </div>
            <div className="row between">
                <span className="sub-title">
                  {renderEllipsis(name, elispsisLength)}
                </span>
                {isMultipleResources && <span className={`DiagramResourceBlock__body-count ${action}`}>+{count}</span>}
            </div>
        </div>
        {targetHandleVisible && (
            <Handle type="target" position={Position.Left} />
        )}
        </div>
    </Popover>
  );
};

export default DiagramResourceBlock;
