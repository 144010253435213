import React, { useRef, useCallback } from "react";
import isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import TableWrapper from "../../../../shared/tableWrapper/tableWrapper";
import { capitalizeFirst } from "../../../../utils/formatting";
import { handleRowClick as handleOpenCloseRowById } from "../../../../utils/table";
import { Tooltip } from "antd";
import { ReactComponent as CreateDeleteIcon } from "../../../../Images/ci/icons/createdelete.svg";
import { ReactComponent as DeleteCreateIcon } from "../../../../Images/ci/icons/deletecreate.svg";
import DiagnosticArr from "./diagnostic/diagnosticArr";
import { WORKFLOWS_ACTIONS_MAP } from "../../../../consts/ci-workflows";

import './diagnostic/diagnostic.scss';

const iconsMap = {
  create: <FontAwesomeIcon className="ApplyResources__action-icon create" icon="plus-circle" />,
  delete: <FontAwesomeIcon className="ApplyResources__action-icon delete" icon="minus-circle" />,
  update: <FontAwesomeIcon className="ApplyResources__action-icon update" icon="pen" />,
  read: <FontAwesomeIcon className="ApplyResources__action-icon read" icon="check-double" />,
  "no-op": <FontAwesomeIcon className="ApplyResources__action-icon no-op" icon="ban" />,
  import: <FontAwesomeIcon className="ApplyResources__action-icon import" icon="download" />,
  failed: <FontAwesomeIcon className="CiModal__modal-icon-failed" icon="times-circle" />,
  success: <FontAwesomeIcon className="CiModal__modal-icon-success" icon="check-double" />,
  createdelete: <CreateDeleteIcon className="PostPlanTable__action-icon-createdelete" />,
  deletecreate: <DeleteCreateIcon className="PostPlanTable__action-icon-deletecreate" />,
}

const ApplyResources = ({ data = [], loading, applyCount, pageSize, setPageSize, setPageNumber, setApplySort, handleOpenPlanConfig, taskId, openRemediationByDefault }) => {
    const { t } = useTranslation("ci-pipeline");

    const apiRef = useRef();
    const dataLength = data?.length;
    const expandedRowIds = data.filter((element = {}) => !isEmpty(element.diagnostics) && element?.highestDiagnosticSeverity?.toLowerCase() === 'error').map((element = {}) => element.id);

    const getDetailPanelContent = useCallback((data = {}) => !isEmpty(data.row?.diagnostics) && <DiagnosticArr diagnostics={data.row.diagnostics} withHeaderBg={false} taskId={taskId} openRemediationByDefault={openRemediationByDefault} /> ,[openRemediationByDefault]);
    const getDetailPanelHeight = useCallback(() => "auto", []);

    const handleRowClick = (params) => { 
      const data = params.row || {};
      const { address } = data;
      handleOpenPlanConfig(address)
    }
    const handleSortChange = (sorter = []) => {
      const { field, sort } = sorter[0] || {};
      setApplySort({ [field]: sort === "asc" ? 1 : -1 });
    };
    const columns = [
        {
            headerName: t("columns.address"),
            field: "address",
            flex: 2,
            sortable: true,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                apiRef.current = params.api;
                const address = params.row?.address;
                return (
                    <Tooltip title={address}>
                        {address}
                    </Tooltip>
                );
            },
        },
        {
          headerName: t("columns.action"),
          field: "action",
          width: 80,
          filterable: false,
          sortable: true,
          disableColumnMenu: true,
          align: "center",
          renderCell: (params) => {
            const action = params.row?.action;
            const actionComponent = iconsMap[action] || action;
            return (
                <Tooltip title={WORKFLOWS_ACTIONS_MAP[action] || capitalizeFirst(action)}>
                  {actionComponent}
                </Tooltip>
            );
          }
        },
        {
          headerName: t("columns.applyDuration"),
          field: "applyDurationSeconds",
          flex: 1,
          filterable: false,
          disableColumnMenu: true,
          sortable: true,
          renderCell: (params) => {
            const applyDuration = params.row?.applyDurationSeconds;
            const applyDurationInSeconds = applyDuration ? `${applyDuration}s`: '-'
            return <span>{applyDurationInSeconds}</span>
          }
        },
        {
          headerName:  t("columns.status"),
          field: "isFailed",
          flex: 1, 
          filterable: false,
          disableColumnMenu: true,
          sortable: true,
          renderCell: (params) => {
            const { isFailed = false } = params.row || {};
            const component = isFailed ? iconsMap.failed :  iconsMap.success
            const status = `APPLY ${isFailed ? "FAILED" : "COMPLETED"}`;
            return (
                <div className="row g5">
                  {component}
                  <span className={`ApplyResources__status ${isFailed ? 'failed' : 'success'}`}>{status}</span>
                </div>
            );
          },
        },
        {
          headerName: "",
          field: "highestDiagnosticSeverity",
          width: 85,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { id: rowId, diagnosticsCount = 0, highestDiagnosticSeverity = '' } = params.row || {};
            if (!diagnosticsCount) return <div></div>;
            const title = `${diagnosticsCount} error message${diagnosticsCount > 1 ? 's' : ''} when tried to apply this resource`;
            const highestLower = highestDiagnosticSeverity.toLowerCase();
            return (
                <Tooltip title={title}>
                  <div>
                    <span className={`Diagnostic__severityBtn ${highestLower}`} onClick={(e) => {
                        if (diagnosticsCount) {
                          e.stopPropagation();
                          return handleOpenCloseRowById(apiRef, rowId);
                        }
                      }}>
                        {capitalizeFirst(highestLower)}
                    </span>
                  </div>
                </Tooltip>
            );
          },
        }
      ];

    return (
      <div className="ApplyResources">
          <TableWrapper
              loading={loading}
              autoHeight={!!dataLength}
              height="100%"
              rowKey="id"
              tableData={data || []}
              columns={columns}
              disableSelectionOnClick
              getDetailPanelContent={getDetailPanelContent}
              getDetailPanelHeight={getDetailPanelHeight}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50]}
              rowCount={applyCount > dataLength ? applyCount : dataLength}
              handlePageChange={setPageNumber}
              onPageSizeChange={setPageSize}
              onRowClick={handleRowClick}
              handleSortChange={handleSortChange}
              expandedRowIds={expandedRowIds}
              serverSide
          />
    </div>
    )

};

export default ApplyResources;