import indexOf from 'lodash/indexOf';
import { ReactComponent as ExploreSandboxIcon } from "../Images/general_icons/onboarding/explore-sandbox.svg";
import { ReactComponent as FreeTrialIcon } from "../Images/general_icons/onboarding/free-trial.svg";

export const ONBORDING_STATUS_NAMES = [
    "welcome",
    "choose-experience",
    "provider",
    "iac",
    "git",
    "notification",
    "done"
];

export const EXPERIENCE_STEP_OPTIONS = {
    exploreSandbox: 'exploreSandbox',
    startFreeTrial: 'startFreeTrial',
};

export const SANDBOX_STATUS = {
    key: "sandbox",
    value: 10,
}
export const CHOOSE_EXPEIRENCE_STATUS = {
    key: "choose-experience",
    value: 2,
}
export const ONBOARDING_CHOOSE_EXPERIENCE_OPTIONS = [
    {
        key: EXPERIENCE_STEP_OPTIONS.startFreeTrial,
        icon: FreeTrialIcon,
    },
    {
        key: EXPERIENCE_STEP_OPTIONS.exploreSandbox,  
        icon: ExploreSandboxIcon,
    }
]
// if -1 than status not found so return the last one idx === "done"
// add +1 to status return - so the first step of wizrd wil be 1 and not 0
export const getStatusIdxByName = (name) => {
    if (name === SANDBOX_STATUS.key) return SANDBOX_STATUS.value;
    let statusNum = indexOf(ONBORDING_STATUS_NAMES, name ,0);
    return statusNum === -1 ? ONBORDING_STATUS_NAMES?.length : statusNum + 1;
}
export const checkIfIsSandboxStatus = (preferences = {}) => {
    const NAMESPACE = "https://infralight.co/";
    const originalAccountId = preferences[`${NAMESPACE}originalAccountId`];
    const currentAccountId = preferences[`${NAMESPACE}account_id`];
    const isSandboxStatus = originalAccountId ? originalAccountId !== currentAccountId : false;
    return isSandboxStatus;
}