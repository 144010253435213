import React, { useState, useEffect } from "react";
import { CI_STEPS } from "../../../../consts/ci-workflows";
import { getInitLogData } from "../../../../redux/actions/ciWorkflowsActions";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../shared/loading/loading";
import LogSyntaxHighlighter from "../logSyntaxHighlighter/logSyntaxHighlighter";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";

const InitStep = ({ taskId, tCi }) => {
    const initLog = useSelector((state) => state.ciWorkflowsReducer.logs?.init) || "";
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (initLog) return;
        fetchInitData();
    }, []);

    const fetchInitData = async () => {
        setLoading(true);
        await dispatch(getInitLogData(taskId, CI_STEPS.init, "raw"))
        setLoading(false);
    };
    if (loading) return <div className="basic-loading"><Loading /></div>;
    if (!initLog) return <AppEmpty customStyle="ciResourcesNotFound" text={tCi("initStep.notFound")}/>;
    return (
        <LogSyntaxHighlighter log={initLog} />
    )
};

export default InitStep;