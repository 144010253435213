import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ReactComponent as ArrowIcon } from "../../../Images/general_icons/arrow.svg";

import { updateUserPreference } from "../../../redux/actions/userPreferencesActions";
import { CHOOSE_EXPEIRENCE_STATUS } from "../../../consts/onbording";
import SpinnerLoader from "../../../shared/spinnerLoader/spinnerLoader";
import ExploreSandboxModal from "./exploreSandboxModal";
import "./exploreSandboxAlert.scss";

const ExploreSandboxAlert = ({ isSandboxStatus }) => {
    const { t } = useTranslation("onBoarding");
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    if (!isSandboxStatus) return null;

    const handleOnReturnToWizard = async() => {
        setLoading(true);
        await dispatch(updateUserPreference(CHOOSE_EXPEIRENCE_STATUS.key, true));
        setLoading(false);
        window.location.reload();
    }
    const handleOnAlertClick = () => {
        setShowModal(true);
    }
    return (
        <>
        <div className="ExploreSandboxAlert">
            <div className="ExploreSandboxAlert__container row g5" onClick={handleOnAlertClick}>
                <span className="ExploreSandboxAlert__text bold">
                {t("exploreSandboxAlert")}
                </span>
                {loading ? <SpinnerLoader/> : <ArrowIcon className="ExploreSandboxAlert__icon"/>}  
            </div>
        </div>
        {showModal && <ExploreSandboxModal onClose={() => setShowModal(false)} onConfirm={handleOnReturnToWizard} loading={loading}/>}
        </>

    )
};

export default ExploreSandboxAlert;