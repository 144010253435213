import React, { useState } from "react";
import { S3_ASSETS } from "../../consts/config";
import AppBtn from "../../shared/appBtn/appBtn";
import { useIntercom } from "react-use-intercom";
import premiumVector from "../../Images/general_icons/premiumVector.svg";
import { ReactComponent as ExploreSandboxIcon } from "../../Images/general_icons/onboarding/explore-sandbox.svg";
import FreeTierModal from "./freeTierModal/freeTierModal";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateUserPreference } from "../../redux/actions/userPreferencesActions";
import { SANDBOX_STATUS } from "../../consts/onbording";
import { useDispatch } from "react-redux";

import "./suspendedAccount.scss";

const SuspendedAccount = () => {
  const { showNewMessages, show } = useIntercom();
  const { logout } = useAuth0();
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const bg = `${S3_ASSETS}/illustrations/suspend_dark.svg`;
  const robot = `${S3_ASSETS}/illustrations/suspend_robot.svg`;

  const handleClick = () => {
    show();
    showNewMessages(
      "Hello Firefly Team, I would like to reactivate my account"
    );
  };

  const handleGoPremium = () => {
    setModalOpen(false);
    show();
    showNewMessages(
      "Hello Firefly Team, I would like to downgrade my account to Free Tier"
    );
  }
  const handleOnExploreSandbox = async() => {
    await dispatch(updateUserPreference(SANDBOX_STATUS.key, 'suspended'));
    window.location.reload();
  };

  return (
    <div
      className={`SuspendedAccount col center`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <span className="SuspendedAccount__title bold">Hello Fireflyer!</span>
      <img src={robot} alt="suspend" />
      <span
          className="SuspendedAccount__link logout row"
          onClick={() => logout({ returnTo: window.location.origin })}
          style={{ gap: '6px' }}
        >
          <FontAwesomeIcon icon="power-off" />
          Logout
        </span>
      <div className="SuspendedAccount__text col center">
        <span>Your Free Trial has ended.</span>
        <span>To continue using Firefly, please unlock Firefly Premium</span>
        <span>or explore Firefly in our sandbox account</span>
      </div>

      <div className="col g20 center SuspendedAccount__actions">
        <AppBtn
          text="Reactivate Firefly"
          icon={<img src={premiumVector} alt="premium" width="22px" />}
          onClick={handleClick}
          style={{ padding: "18px 15px", fontWeight: "600" }}
        />
        <span className="underline pointer purple-text" onClick={handleOnExploreSandbox}>Explore Sandbox</span>
      </div>
      <FreeTierModal
        visible={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleGoPremium={handleGoPremium}
      />
    </div>
  );
};

export default SuspendedAccount;
