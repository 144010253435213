import React, { useEffect } from "react";
import Diagnostic from "./diagnostic";
import isEmpty from "lodash/isEmpty";
import { sendEvent } from "../../../../../utils/amplitude";
import { CiEvents } from "../../../../../utils/amplitudeEvents";

const DiagnosticArr = ({ diagnostics = [], withHeaderBg = true, taskId, openRemediationByDefault = false }) => {
    
    useEffect(() => {
        if (openRemediationByDefault) {
            sendEvent(CiEvents.clickedRemdiationDiagnostic, { taskId, name: "All diagnostics" });
        }
    }, [openRemediationByDefault]);

    if (isEmpty(diagnostics)) return null;
    return <div className="DiagnosticArr col g10">
        {diagnostics.map((diagnostic) => {
            return <Diagnostic key={diagnostic?.id} item ={diagnostic} withHeaderBg={withHeaderBg} taskId={taskId} openRemediationByDefault={openRemediationByDefault} />})
        }
    </div>
};

export default DiagnosticArr;
